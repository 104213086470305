<template>
    <div>
        
        <FormComponent title="添加客户" :data="formData" canEdit="true" @submit="createdKehu"></FormComponent>
    </div>

</template>

<script setup>
import {ref,reactive} from "vue"
import FormComponent from '@/components/FormComponent.vue';
import { addkehu} from "@/api/kehu"
import { ElMessage } from "element-plus";

const createdKehu=async (params)=>{
        let data=await addkehu(params);
        if(data.code==200){
            ElMessage.success(data.msg)
        }else{
            ElMessage.error(data.msg)
        }
    }
const formData=reactive({
        
       
        
        name:{
            label:"客户名称",
            value:"",
            type:"text",
            require:true,
            placeholder:"请输入客户名称"
        },
        lxr:{
            label:"联系人",
            value:"",
            type:"text",
            require:true,
            placeholder:"请输入联系人姓名"
        },

        
        phone:{
            label:"客户手机号",
            value:"",
            type:"text",
            
            placeholder:"请输入客户手机号"
        },
        address:{
            label:"客户地址",
            value:"",
            type:"text",
            require:true,
           
            placeholder:"请输入客户地址"
        },

        type:{
            label:"客户类型",
            value:"",
            type:"select",
            require:true,
            options:[
                {
                    label:"个人",
                    value:1
                },
                {
                    label:"门店",
                    value:2
                }
            ],
            placeholder:"请输入客户地址"
        },

        remark:{
            label:"备注",
            value:"",
            type:"text",
           
            placeholder:"请输入备注"
        },
        
        img:{
            label:"门店照片",
            value:null,
            type:"image",
           
            placeholder:"请选择门店照片"
        },
       
       
       
       
    })

</script>
