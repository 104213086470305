<template>
    <div>
        
        <FormComponent title="生成客户码" :data="formData" canEdit="true" @submit="createdKHM"></FormComponent>
    </div>

    <div class="flex">
       
       <input type="file" @change="change">
       <el-button @click="piliang">批量生成</el-button>
       <el-tag>
    如果提示服务器错误,请按F5 刷新网页重试
   </el-tag>
   </div>
   

   <div class="tips">
      表格模板
       
   </div>
   <table border="1">
       <tr>
           <td>车架码
           </td>
           <td>电机码
           </td>
           <td>
               电池批次码
           </td>
           <td>
               型号
           </td>
           <td>
               客户名称
           </td>
           <td>
               手机号
           </td>
           <td>
               收货地址
           </td>
           <td>
               价格
           </td>
           <td>
               业务员
           </td>
           <td>
               模块编号
           </td>
       </tr>
       <tr>
       <td>
           2589220555
       </td>
       <td>
           JNW-350w
       </td>
       <td>
           xxxxx
       </td>
       <td>
           xxxxx
       </td>
       <td>
           xxxxx
       </td>
       <td>
           xxxxx
       </td>
       <td>
           xxxxx
       </td>
       <td>
           xxxxx
       </td>
       <td>
           xxxxx
       </td>
       <td>
           xxxxx
       </td>
   </tr>
   </table>

   <el-dialog
    
    v-model="dialogVisible"
    title="录入结果"
    width="400px"
    
  >
   <div  v-if="count">
    录入成功:{{count}} 条
   </div>
   <div style="color: #f00;" v-if="ycz">
    失败:{{ycz}} 条
   </div>
   <div v-if="ycz">
    下列字段已被使用:
    <div v-for="i in cunzai">
        {{ i }}
    </div>
   </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import {ref,reactive} from "vue"
import FormComponent from '@/components/FormComponent.vue';
import { add,addForFile,getpcmO} from "@/api/khm"
import { ElMessage } from "element-plus";
import Address from "../shop/address.vue";
const dialogVisible=ref(false)
const count=ref(0)
const cunzai=ref([])
const ycz=ref(0)
let file=null
const ps=ref([])
getpcmO().then(res=>{
ps.value=res.data
})
const piliang=()=>{
    
    if(file==null){
        alert("请上传文件")
        
    }else{
        batchEntry(file)
        
    }
}
const createdKHM=async (params)=>{
        let data=await add(params);
        if(data.code==200){
            ElMessage.success(data.msg)
        }else{
            ElMessage.error(data.msg)
        }
    }
const batchEntry=(file)=>{
        addForFile({file}).then(res=>{
            if(res.code==200){
                count.value=res.count
                cunzai.value=res.cunzai
                ycz.value=res.ycz
                dialogVisible.value=true
            }else{
                ElMessage.error(res.msg)
            }
        })
    }
const formData=reactive({
        
       
        
        cjm:{
            label:"车架码",
            value:"",
            type:"text",
            require:true,
            placeholder:"请输入车架码"
        },
        djm:{
            label:"电机码",
            value:"",
            type:"text",
            require:true,
            placeholder:"请输入电机码"
        },
        dcm:{
            label:"电池批次码",
            value:"",
            type:"select",
            require:true,
            options:ps,
            placeholder:"请选择电池批次码"
        },


        model:{
            label:"型号",
            value:"",
            type:"text",
           
            placeholder:"请输入型号"
        },
        dk:{
            label:"模块编号",
            value:"",
            type:"text",
           
            placeholder:"请输入型号"
        },
        user_name:{
            label:"用户名称",
            value:"",
            type:"text",
            
            placeholder:"请输入用户名称"
        },
        phone:{
            label:"手机号",
            value:"",
            type:"text",
          
            placeholder:"请输入手机号"
        },
        address:{
            label:"收货地址",
            value:"",
            type:"text",
           
            placeholder:"请输入收货地址"
        },
        price:{
            label:"价格",
            value:"",
            type:"text",
           
            placeholder:"请输入价格"
        },
        ywy:{
            label:"业务员",
            value:"",
            type:"text",
           
            placeholder:"请输入业务员"
        },
    })
const change=(e)=>{
    file = e.target.files[0];
      let allowedExtensions = /(\.xls)$/i;
      if (!allowedExtensions.exec(file.name)) {
        file=null
        alert('只允许上传xls格式的文件!');
        e.target.value = '';
        return false;
      }
      
}
</script>
