import { createRouter, createWebHashHistory } from 'vue-router'
import adminIndexPage from "@/pages/admin/indexPage.vue"
import addAdminPage from "@/pages/admin/addAdminPage.vue"
import storeIndexPage from "@/pages/store/indexPage.vue"
import addStorePage from "@/pages/store/addStorePage.vue"
import storeConfig from "@/pages/store/storeConfigPage.vue"
import addfzd from "@/pages/store/addfzd.vue"




import refresh from "@/pages/refresh/RefreshPage.vue"


import homePage from "@/pages/home/homePage.vue"


import menuIndex from "@/pages/menu/menuIndex.vue"
import addMenu from "@/pages/menu/addMenu.vue"
import itemModel from "@/pages/leaseItems/itemModelpage.vue"
import billingRole from "@/pages/leaseItems/billingRulePage.vue"
import leaseItemDetail from "@/pages/leaseItems/leaseItemDetailPage.vue"
import addbillingrule from "@/pages/leaseItems/addBillingRulePage.vue"
import addLeaseItemPage from "@/pages/leaseItems/addLeaseItemPage.vue"
import leaseItem from "@/pages/leaseItems/leaseItemPage.vue"
import kucun from "@/pages/leaseItems/kucun.vue"




import powerplan from "@/pages/leaseItems/powerPlanPage.vue"




import addItemModel from "@/pages/leaseItems/addItemModelpage.vue"
import roleIndex from "@/pages/role/roleIndex.vue"

import itemIndex from "@/pages/purchase/itemIndexPage.vue"
import addItem from "@/pages/purchase/addItemPage.vue"
import addSupplier from "@/pages/purchase/addSupplierPage.vue"
import addPurchase from "@/pages/purchase/addPurchasePage.vue"
import userIndex from "@/pages/user/userIndexPage.vue"
import userLeaseList from "@/pages/user/userLeaseListPage.vue"
import userCashflow from "@/pages/user/userCashFlowPage.vue"
import idReview from "@/pages/user/idReview.vue"
import userCenter from "@/pages/user/userCenter.vue"
import ph from "@/pages/user/ph.vue"

import adminCash from "@/pages/finance/adminCashPage"
import cashRule from "@/pages/finance/cashRulePage"
import cashReview from "@/pages/finance/cashReviewPage"
import adminCashFlow from "@/pages/finance/adminCashFlowPage"
import cashapply from "@/pages/finance/cashapply"
import freezeCash from "@/pages/finance/freezeCash"
import overview from "@/pages/finance/overview"
import tk from "@/pages/finance/tk"



import orderIndex from "@/pages/order/orderIndexPage.vue"
import returnCarReview from "@/pages/order/returnCarReviewPage.vue"
import transferreview from "@/pages/order/carChangeReviewPage.vue"

import leaseData from "@/pages/statistics/leaseData.vue"
import bigDataScreen from "@/pages/statistics/bigDataScreen.vue"
import deviceTJ from "@/pages/statistics/deviceTJ.vue"
import yewuyuan from "@/pages/statistics/yewuyuan.vue"
import fenxi from "@/pages/statistics/fenxi.vue"




import banner from "@/pages/marketing/banner.vue"
import coupon from "@/pages/marketing/coupon.vue"

import notFound from "@/pages/notFound.vue"

import shouhouIndex from "@/pages/shouhou/index"

import shopIndex from "@/pages/shop/index"
import addGoods from "@/pages/shop/addGoods"

import goodsList from "@/pages/shop/goodsList"

import address from "@/pages/shop/address"
import color from "@/pages/shop/color"
import battery from "@/pages/shop/battery"
import shopBanner from '@/pages/shop/banner'



import sysLogIndex from "@/pages/syslog/index.vue"
import leaseItemLog from "@/pages/syslog/leaseItemLog.vue"
import leaseLog from "@/pages/syslog/leaseLog.vue"
import switchCarLog from "@/pages/syslog/switchCarLog.vue"
import startLog from "@/pages/syslog/startLog.vue"
import yichang from "@/pages/syslog/yichang.vue"


import jmsIndex from "@/pages/jms/index.vue"
import txsq from "@/pages/jms/txsq.vue"
import zjls from "@/pages/jms/zjls.vue"
import supplierIndex from "@/pages/supplier/index.vue"
import supplierAdd from "@/pages/supplier/add.vue"

import productIndex from "@/pages/product/index.vue"

import priceDR from "@/pages/product/priceDR.vue"

import improtProduct from "@/pages/product/improtProduct.vue"






import productAdd from "@/pages/product/add.vue"
import productType from "@/pages/product/type.vue"
import addType from "@/pages/product/addType.vue"
import addZJ from "@/pages/product/addzj.vue"
import zjlist from "@/pages/product/zjlist.vue"
import zjedit from "@/pages/product/zjedit.vue"




import kucunIndex from "@/pages/kucun/index.vue"
import kucunJilu from "@/pages/kucun/jilu.vue"
import zjc from "@/pages/kucun/zjc.vue"
import wxc from "@/pages/kucun/wxc.vue"

import BomAdd from "@/pages/bom/add.vue"
import BomIndex from "@/pages/bom/index.vue"
import BomXq from "@/pages/bom/xq.vue"

import hsz from "@/pages/bom/hsz.vue"


import importBom from "@/pages/bom/importBom.vue"



import produceIndex from "@/pages/produce/index.vue"
import produceFenxi from "@/pages/produce/fenxi.vue"

import churuku from "@/pages/moblie/churuku.vue"
import bomlist from "@/pages/moblie/bomlist.vue"
import bomxq from "@/pages/moblie/bomxq.vue"
import dbjl from "@/pages/moblie/dbjl.vue"
import cplb from "@/pages/moblie/cplb.vue"
import kclb from "@/pages/moblie/kclb.vue"
import syt from "@/pages/moblie/syt.vue"
import cpxq from "@/pages/moblie/cpxq.vue"
import zjcrk from "@/pages/moblie/zjcrk.vue"
import zj from "@/pages/moblie/zj.vue"
import zclb from "@/pages/moblie/zclb.vue"
import zchsz from "@/pages/moblie/zchsz.vue"
import zcxq from "@/pages/moblie/zcxq.vue"
import kehuList from "@/pages/moblie/kehuList.vue"

import assetIndex from "@/pages/asset/index.vue"


import assetType from "@/pages/asset/type.vue"
import assetHsz from "@/pages/asset/zchsz.vue"

import kHMIndex from "@/pages/khm/index.vue"
import createKHM from "@/pages/khm/createKHM.vue"
import pcm from "@/pages/khm/pcm.vue"

import kehuIndex from "@/pages/kehu/index.vue"
import kehuAdd from "@/pages/kehu/addKehu.vue"









const routes=[

  {
    path:"/",
    component:homePage
  },
  {
    path:"/kuhu",
    children:[
      {
        path:"index",
      component:kehuIndex
      },
      {
        path:"addkehu",
      component:kehuAdd
      }
    ]
  },
  {
    path:"/khm",
    children:[
      {
        path:"index",
        component:kHMIndex
      },
      {
        path:"pcm",
        component:pcm
      },
      {
        path:"createKHM",
        component:createKHM
      }
    ]
  },
  {
    path:"/asset",
    children:[


      {
        path:"index",
        component:assetIndex
      },
      {
        path:"type",
        component:assetType
      },
      {
        path:"zchsz",
        component:assetHsz
      }
    ]
  },
  {

    path:"/moblie",
    children:[
      {
        path:"kehuList",
        component:kehuList
      },

      {
        path:'zcxq',
        component:zcxq
      },
      {
        path:"zclb",
        component:zclb
      },
      {
        path:"zchsz",
        component:zchsz
      },
      {
        path:"zj",
        component:zj
      },
      {
        path:"zjcrk",
        component:zjcrk
      },
      {
        path:"cpxq",
        component:cpxq
      },
      {
        path:"dbjl",
        component:dbjl
      },
      {
        path:"cplb",
        component:cplb
      },
      {
        path:"kclb",
        component:kclb
      },
      {
        path:"syt",
        component:syt
      },


      {
        path:"bomxq",
        component:bomxq
      },
      {
        path:"bomlist",
        component:bomlist
      },
      {
        path:"churuku",
        component:churuku
      }
    ]
  },
  {
    path:"/produce",
    children:[
      {
        path:"index",
        component:produceIndex
      },
      {
        path:"fenxi",
        component:produceFenxi
      },
      
    ]
  },
  {
    path:"/bom",
    children:[

      
      {
        path:"importBom",
        component:importBom
      },
      {
        path:"add",
        component:BomAdd
      },
      {
        path:"xq",
        component:BomXq
      },
      {
        path:"index",
        component:BomIndex
      },
      {
        path:"hsz",
        component:hsz
      }
    ]
  },
  {
    path:"/kucun",
    children:[
      {
        path:"index",
        component:kucunIndex
      },
      {
        path:"zjc",
        component:zjc
      },
      {
        path:"wxc",
        component:wxc
      },
      {
        path:"jilu",
        component:kucunJilu
      }
     
    ]
  },
  {
    path:"/product",
    children:[
      {
        path:"improtProduct",
        component:improtProduct
       },
       {
        path:"priceDR",
        component:priceDR
       },
      {
        path:"zjedit",
        component:zjedit
       },
      {
        path:"zjlist",
        component:zjlist
       },
      {
        path:"addzj",
        component:addZJ
       },
     {
      path:"addType",
      component:addType
     },
     {
      path:"index",
      component:productIndex
     },
     {
      path:"product_type",
      component:productType
     },
     {
      path:"add",
      component:productAdd
     }
    ]
  },
  {
    path:"/supplier",
    children:[
     {
      path:"index",
      component:supplierIndex
     },
     {
      path:"add",
      component:supplierAdd
     }
    ]
  },
  {
    path:'/jms',
    children:[
      {
        path:"index",
        component:jmsIndex
      },
      {
        path:"txsq",
        component:txsq
      },
      {
        path:"zjls",
        component:zjls
      }
    ]
  },
  {
    path:"/shop",
    children:[
      {
        path:'banner',
        component:shopBanner
      },
      {
        path:'battery',
        component:battery
      },
      {
        path:'colors',
        component:color
      },
      {
        path:"addGoods",
        component:addGoods
      },
      {
        path:"goodsList",
        component:goodsList
      },
      {
        path:"address",
        component:address
      },
      {
        path:"index",
        component:shopIndex
      }
    ]
  },
  
  {
    path:"/shouhou",
    children:[
      {
        path:"index",
        component:shouhouIndex
      }
    ]
  },
  {
    path:"/marketing",
    children:[
      {
        path:"banner",
        component:banner
      },
      {
        path:"coupon",
        component:coupon
      }
    ]
  },
  {
    path:"/statistics",
    children:[
      {
        component:yewuyuan,
        path:"yewuyuan"
      },
      {
        component:fenxi,
        path:"fenxi"
      },
      
      {
        component:deviceTJ,
        path:"deviceTJ"
      },
      {
        component:leaseData,
        path:"leasedata"
      },
      {
        component:bigDataScreen,
        path:"bigDataScreen"
      }
    ]
  },
  {
    path:"/syslog",
    children:[
      {
        component:leaseItemLog,
        path:"leaseItemLog"
      },
      {
        component:yichang,
        path:"yichang"
      },
      {
        component:startLog,
        path:"startLog"
      },
      
      {
        component:leaseLog,
        path:"leaseLog"
      },
      {
        component:switchCarLog,
        path:"switchCarLog"
      },
      {
        component:sysLogIndex,
        path:"index"
      }
    ]
  },
  {
    path:"/xiarefresh",
    component:refresh
  },
  {
    path:'/finance',
    children:[
      {
        path:'cashapply',
        component:cashapply
      },
      {
        path:'overview',
        component:overview
      },
      {
        path:'tk',
        component:tk
      },
      {
        path:"admincash",
        component:adminCash
      },
      {
        path:"freezecash",
        component:freezeCash
      },
      
      {
        path:"cashrule",
        component:cashRule
      },
      {
        path:"cashreview",
        component:cashReview
      },
      {
        path:"admincashflow",
        component:adminCashFlow
      }
    ]
  },
  {
    path:"/order",
    children:[
      {
        path:"orderIndex",
        component:orderIndex
      },
      {
        path:"returncarreview",
        component:returnCarReview
      },{
        path:'transferreview',
        component:transferreview
      }
    ]
  },
  {
    path:"/user",
    children:[
      {
        path:"index",
        component:userIndex
      },
      {
        path:"userCenter",
        component:userCenter
      },
      {
        path:"ph",
        component:ph
      },
      {
        path:"idReview",
        component:idReview
      },
      
      {
        path:"leaserecord",
        component:userLeaseList
      },
      {
        path:"cashflow",
        component:userCashflow
      }

    ]
  },
  {
    path:"/purchase",
    children:[
      
      {
        path:'addPurchase',
        component:addPurchase
      },
      
      
      
      {
        path:'item',
        component:itemIndex
      },
      {
        path:"addItem",
        component:addItem
      }
    ]
  },
  {
    path:"/role",
    children:[
      {
        path:"index",
        component:roleIndex
      }
    ]
  },
  {
    path:"/leaseitem",
    children:[

    {
      path:"kucun",
      component:kucun
    },
      {
        path:"itmemodel",
        component:itemModel
      },
      {
        path:"detail",
        component:leaseItemDetail
      },
      
      {
        path:"addItemModel",
        component:addItemModel
      },
      {
        path:"add",
        component:addLeaseItemPage
      },
      {
        path:"index",
        component:leaseItem
      },
      {
        path:"billingRole",
        component:billingRole
      },
      {
        path:"addbillingrule",
        component:addbillingrule
      },
      
      {
        path:"powerplan",
        component:powerplan
      }

      
    ]
  },
  {
    path:'/menu',
    children:[
      {
        path:'index',
        component:menuIndex
      },
      {
        path:'add',
        component:addMenu
      }
    ]
  },
  {
    path:"/admin",
    children:[
      {
        path:"add",
        component:addAdminPage
      },
      {
        path:"index",
        component:adminIndexPage
      }
    ]
  },
  {
    path:"/store",
    
    children:[
      {
        path:"add",
        component:addStorePage
      },
      {
        path:"addfzd",
        component:addfzd
      },
      
      {
        path:"setting",
        component:storeConfig
      },
      
      {
        path:"index",
        component:storeIndexPage
      }
    ]
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: notFound }
];
// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
// ]
import {a} from "./auto.js"
let b=a();
routes.push(...b)
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
