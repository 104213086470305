import { getpcmList } from "@/api/khm";
import { index } from "@/api/kehu";
import { ref, reactive } from "vue";
import { useBase } from "@/use/base";
import { getPO } from "@/api/mobile";
export function useIndex() {
  const searchData = reactive({
    name: {
      label: "客户名称",
      type: "text",
      placeholder: "输入客户名称",
      value: "",
    },
    lxr: {
      label: "联系人",
      type: "text",
      placeholder: "输入联系人",
      value: "",
    },
    phone: {
      label: "联系电话",
      type: "text",
      placeholder: "输入联系电话",
      value: "",
    },
    address: {
      label: "客户地址",
      type: "text",
      placeholder: "输入客户地址",
      value: "",
    },
    type: {
      label: "客户类型",
      type: "select",
      options: [
        {
          label: "个人客户",
          value: 1,
        },
        {
          label: "门店客户",
          value: 2,
        },
      ],
      value: "",
    }
  });
  const headers = [
    {
      label: "ID",
      prop: "id",
    },
    {
      label: "客户名称",
      prop: "name",
    },
    {
      label: "联系人",
      prop: "lxr",
    },

    {
      label: "客户手机号",
      prop: "phone",
    },
    {
      label: "客户地址",
      prop: "address",
    },
    {
      label: "客户类型",
      prop: "type",
    },
    {
      label: "门店照片",
      prop: "img",
    },

    {
      label: "添加时间",
      prop: "create_time",
    },
    {
      label: "操作",
      prop: "caozuo",
    },
  ];

  const { search, tableObjet } = useBase(index, searchData, null, null);

  return { headers, searchData, search, tableObjet };
}
export function usePCM() {
  const ps = ref([]);
  getPO().then((res) => {
    ps.value = res.data.map((i) => {
      return {
        label: i.name,
        value: i.id,
      };
    });
  });
  const searchData = reactive({
    title: {
      label: "批次码",
      type: "text",
      placeholder: "输入批次码搜索",
      value: "",
    },
    product_id: {
      label: "产品",
      type: "select",
      placeholder: "选择产品",
      options: ps,
      value: "",
    },
  });
  const headers = [
    {
      label: "ID",
      prop: "id",
    },

    {
      label: "批次码",
      prop: "title",
    },
    {
      label: "产品",
      prop: "name",
    },
    {
      label: "数量",
      prop: "number",
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "操作",
      prop: "caozuo",
    },
  ];

  const { search, tableObjet } = useBase(getpcmList, searchData, null, null);

  return { headers, searchData, search, tableObjet, ps };
}
