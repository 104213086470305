import { index,getpcmList } from "@/api/khm";
import { ref, reactive } from "vue";
import { useBase } from "@/use/base";
import { getPO} from "@/api/mobile"
export function useIndex() {
  const searchData = reactive({
    cjm: {
      label: "车架码",
      type: "text",
      placeholder: "输入车架码",
      value: "",
    },
    djm: {
      label: "电机码",
      type: "text",
      placeholder: "输入电机码",
      value: "",
    },
    dcm: {
      label: "电池批次码",
      type: "text",
      placeholder: "输入电池批次码",
      value: "",
    },
    user_name: {
      label: "客户名称",
      type: "text",
      placeholder: "输入客户名称",
      value: "",
    },
    model: {
      label: "型号",
      type: "text",
      placeholder: "输入型号",
      value: "",
    },
    address: {
      label: "收货地址",
      type: "text",
      placeholder: "输入收货地址",
      value: "",
    },
    phone: {
      label: "手机号",
      type: "text",
      placeholder: "输入手机号码",
      value: "",
    },
    ywy: {
      label: "业务员",
      type: "text",
      placeholder: "输入业务员",
      value: "",
    },
  });
  const headers = [
    {
      label: "ID",
      prop: "id",
    },
    {
      label: "设备二维码",
      prop: "qrcode",
    },
    
    {
      label: "车架码",
      prop: "cjm",
    },
    {
      label: "电机码",
      prop: "djm",
    },
    {
      label:"模块编号",
      prop:"dk"
    },
    {
      label: "电池批次码",
      prop: "dcm",
    },
    {
      label: "型号",
      prop: "model",
    },
    {
      label: "客户名称",
      prop: "user_name",
    },
    {
      label: "手机号",
      prop: "phone",
    },
    {
      label: "收货地址",
      prop: "address",
    },
    {
      label: "价格",
      prop: "price",
    },
    {
      label: "业务员",
      prop: "ywy",
    },
    {
      label: "生成时间",
      prop: "create_time",
    },
    {
      label: "操作",
      prop: "caozuo",
    },
  ];

  const { search, tableObjet } = useBase(index, searchData, null, null);

  return { headers, searchData, search, tableObjet };
}
export function usePCM() {
  const ps=ref([])
  getPO().then(res => {
    ps.value = res.data.map(i=>{
      return {
        label:i.name,
        value:i.id
      }
    })
})
  const searchData = reactive({
    title: {
      label: "批次码",
      type: "text",
      placeholder: "输入批次码搜索",
      value: "",
    },
    product_id: {
      label: "产品",
      type: "select",
      placeholder: "选择产品",
      options:ps,
      value: "",
    },
   
  });
  const headers = [
    {
      label: "ID",
      prop: "id",
    },
  

    {
      label: "批次码",
      prop: "title",
    },
    {
      label: "产品",
      prop: "name",
    },
    {
      label: "数量",
      prop: "number",
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "操作",
      prop: "caozuo",
    },
  ];

  const { search, tableObjet } = useBase(getpcmList, searchData, null, null);

  return { headers, searchData, search, tableObjet,ps };
}