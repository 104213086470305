<template>
    <div style="height: 100vh;width: 100vw;display: flex; flex-direction: column;">
        <div class="title">
            客户列表
        </div>
        <div class="search">
            <input type="text" placeholder="客户名称" v-model="searchData.name.value">
            <input type="text" placeholder="客户地址" v-model="searchData.address.value">
            <button @click="search()">搜索</button>
        </div>
        <div class="list">
            <div class="item" v-for="item in tableObjet.data">
                <div class="hang">
                    <div class="left">
                        客户类型:
                    </div>
                    <div class="right">
                        {{ item.type==1?'个人客户':'门店客户' }}
                    </div>
                </div>
                <div class="hang">
                    <div class="left">
                        客户名称:
                    </div>
                    <div class="right">
                        {{ item.name }}
                    </div>
                </div>
                <div class="hang">
                    <div class="left">
                        联系人:
                    </div>
                    <div class="right">
                        {{ item.lxr }}
                    </div>
                </div>
                <div class="hang">
                    <div class="left">
                        联系电话:
                    </div>
                    <div class="right">
                        {{ item.phone }}
                    </div>
                </div>
                <div class="hang">
                    <div class="left">
                        客户地址:
                    </div>
                    <div class="right">
                        {{ item.address }}
                    </div>
                </div>
                <div class="hang">
                    <div class="left">
                        门店照片:
                    </div>
                    <div class="right">
                        <img :src="'https://erp.jimoveebikes.com/storage/'+item.img" alt="" style="width: 200px;height: 100px;object-fit: cover;border-radius: 5px;">
                    </div>
                </div>
                <div class="hang">
                    <div class="left">
                        备注信息:
                    </div>
                    <div class="right" style="color: #f00;">
                        {{ item.remark }}
                    </div>
                </div>
                <div class="hang">
                    <div class="left">
                        录入时间:
                    </div>
                    <div class="right">
                        {{ item.create_time }}
                    </div>
                </div>
            </div>
        </div>
        <div class="fyq">
            共{{ tableObjet.dataCount }} 条数据 
        </div>

    </div>
</template>

<script setup>

import { useIndex } from "@/use/kehu"


const { search, searchData, tableObjet, headers, o } = useIndex()
</script>
<style scoped lang="scss">
*{
    box-sizing: border-box;
}
.search{
    display: flex;
    height: 50px;
    align-items: center;
    padding: 5px;
    input{
        width: 150px;
        margin: 0 5px;
        height: 35px;
        border-radius: 5px;
        text-align: center;
        border:1px solid #aaa;
    }
    button{
        height: 35px;
        background-color: #5677fc;
        color:#fff;
        border:none;
        border-radius: 5px;
        width: 100px ;

    }
}
.list {
    flex: 1;
    overflow-y: auto;
   
}
.item{
    background-color: #eee;
    margin: 20px;
    border-radius: 15px;
    padding: 10px;

    .hang{
        min-height: 35px;
        padding: 10px 0;
        border-bottom: 1px solid #f7f7f7;
        align-items: center;
        display: flex;
        .left{
            width: 100px;
            color: #aaa;
        }
        .right{
            flex:1;
            overflow: auto;
        }
    }
}
.fyq{
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
    background-color: #5677fc;
    color: #fff;
    
}

.title {
    text-align: center;
    height: 50px;
    line-height: 50px;
    margin-top: 0;

}
</style>